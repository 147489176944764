'use client'
import useScrollDirection from '@/lib/hooks/useScrollDirection'
import React, { RefObject, useEffect, useRef, useState } from 'react'

import { useOnScreen } from '@/lib/hooks/useOnScreen'
import { cn } from '@/lib/utils'
import Image from 'next/image'
import Link from 'next/link'

const Header = () => {
  const [isOpen, setIsOpen] = useState(false)
  const scrollDirection = useScrollDirection()
  const { isOnScreen } = useOnScreen()
  const menuRef: RefObject<HTMLDivElement> = useRef(null)

  const handleToggleMenu = () => {
    setIsOpen(!isOpen)
  }

  const handleCloseMenu = () => {
    setIsOpen(false)
  }
  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        menuRef.current &&
        isOpen &&
        !menuRef.current.contains(event.target as Node)
      ) {
        handleCloseMenu()
      }
    }

    document.addEventListener('click', handleOutsideClick)

    return () => {
      document.removeEventListener('click', handleOutsideClick)
    }
  }, [isOpen])
  return (
    <header
      className={cn(
        'fixed z-50 flex h-[78px] w-full justify-center bg-slate-800/90 bg-gradient-to-b to-transparent transition-all duration-500 ease-in-out',
        {
          'fixed top-0': scrollDirection === 'up',
          'fixed top-[-78px]': scrollDirection === 'down',
          'bg-slate-800/60': isOnScreen,
        }
      )}
    >
      <div className="flex w-full max-w-7xl items-center justify-between gap-2 px-12">
        <Link href={'/'}>
          <Image
            width={42}
            height={42}
            src={'/images/logo.svg'}
            alt="Logo"
            className=""
          />
        </Link>
        <div className="hidden items-center justify-center gap-8 text-white md:flex lg:gap-12 ">
          <Link href={'/#Dúplex'}>Dúplex</Link>
          <Link href={'#2 Ambientes'}>2 Ambientes</Link>
          <Link href={'/#Monoambiente'}>Monoambiente</Link>
          <Link href={'/#Reservar'}>Reservar</Link>
        </div>
        <div className="flex gap-3 md:gap-2">
          <Link href="https://www.instagram.com/anatoliasuites"
            target='_blank'
          >
            <Image
              width={48}
              height={48}
              src={'/images/facebook.svg'}
              alt="Follow us on Facebook"
              className="h-9 w-9  md:h-7 md:w-7"
            />
          
          </Link>
          <Link href="https://www.facebook.com/people/Anatolia-Suites/100008504958824/"
            target='_blank'
          >
            <Image
              width={48}
              height={48}
              src={'/images/instagram.svg'}
              alt="Follow us on Instagram"
              className="h-9 w-9  md:h-7 md:w-7"
            />

          </Link>
        </div>
        <div className="cursor-pointer md:hidden" onClick={handleToggleMenu}>
          <Image
            width={44}
            height={44}
            src={'/icons/menu.svg'}
            alt="Follow us on Instagram"
          />
        </div>
      </div>
      {/* Sidebar */}
      <div
        className={cn(
          'fixed left-0 top-0 z-10 flex h-full w-64 transform  flex-col  bg-[#1A2238] pl-12 pt-24 text-white transition-transform md:hidden',
          {
            '-translate-x-64': !isOpen,
          }
        )}
        ref={menuRef}
      >
        <div className="flex items-center pb-24  ">
          <button onClick={handleCloseMenu}>
            <IconXmark className="h-8 w-8" />
          </button>
        </div>
        <div className="flex flex-col gap-6">
          <div className="mb-8">
            <Image width={67} height={82} src={'/images/logo.svg'} alt="Logo" />
          </div>
          <div className="mb-8 flex flex-col gap-4 text-2xl font-medium text-white">
            <Link href={'/#Dúplex'} onClick={handleCloseMenu}>
              Dúplex
            </Link>
            <Link href={'#2 Ambientes'} onClick={handleCloseMenu}>
              2 Ambientes
            </Link>
            <Link href={'/#Monoambiente'} onClick={handleCloseMenu}>
              Monoambiente
            </Link>
            <Link href={'/#Reservar'} onClick={handleCloseMenu}>
              Reservar
            </Link>
          </div>
          <div className="flex gap-3 md:gap-2">
            <Image
              width={48}
              height={48}
              src={'/images/facebook.svg'}
              alt="Follow us on Facebook"
              className="h-9 w-9 md:h-7 md:w-7"
            />
            <Image
              width={48}
              height={48}
              src={'/images/instagram.svg'}
              alt="Follow us on Instagram"
              className="h-9 w-9 md:h-7 md:w-7"
            />
          </div>
        </div>
      </div>
    </header>
  )
}
function IconXmark(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M18 6 6 18" />
      <path d="m6 6 12 12" />
    </svg>
  )
}
export default Header
